import React, {Component} from 'react';
import './index.css';

function Hour(props) {
    const hr = props.time.getHours();
    return <span>{ hr === 0 || hr === 12 ? "12" : hr%12}</span>;
}

function Minute(props) {
    return <span>
      { ("00" + props.time.getMinutes()).substr(-2,2) }
    </span>;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { time : new Date() };
    }

    componentDidMount() {
        this.interval = setInterval( ()=>{
            delete this.state.time;
            this.setState({
                time : new Date()
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const pm = this.state.time.getHours() >= 12;
        return (
            <div className="App">
              <div>
                <Hour time={this.state.time}/>:<Minute time={this.state.time}/>
                <span className="ampm">{ pm ? "p" : "a" }</span>
              </div>
            </div>
        );
    }
}

export default App;
